import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { header } from "../styles/home.module.css"
import { btn } from "../styles/home.module.css"
export default function Home() {
  return (
    <Layout pageTitle="Home">
      <section className={header}>
        <div>
          <h2>Home of Caribbean Music Producers</h2>
          <h3> We are Good Vibes Designers</h3>
          <p>Welcome under the mango tree grab one riddim and enjoy</p>
          <StaticImage
            alt="HomeVibe"
            src="../images/sainteLuce.jpeg"
          />
          <Link className={btn} to="/portfolio">
            Check our Portfolio
          </Link>
        </div>
      </section>
    </Layout>
  )
}
